import React, { useState } from 'react';
import Loader from './loader';
import Timer from './Timer';
import SuccessComponent from './successComponent';
import DangerComponent from './dangerComponent';
import RestHelper from '../RestHelper';
import ReCAPTCHA from "react-google-recaptcha";

function CustomerLoginComponent() {
  const [loading, IsLoad] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loginType] = useState(0); // for customer
  const [errors, setErrors] = useState({});
  const [timerStart, setTimerStart] = useState(1);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const backgroundImageUrl = process.env.REACT_APP_IMAGE_URL + 'customer_login_bg.png';

  const reCaptchaRef = React.useRef();

  function validateForm() {
    const errors = {};
    setName(name.trim());
    setEmail(email.trim());
    if (!name) {
      errors.name = 'Name is required';
    } else if (name.length < 3) {
      errors.name = 'Name must be at least 3 characters';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      errors.email = 'Invalid email format';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  }

  async function submitDetails(event) {
    event.preventDefault();
    if (validateForm()) {
      
      // You can access the reCAPTCHA token using reCaptchaRef
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();

      setIsDisabled(true);
      IsLoad(true);

      // Proceed with form submission
      let apiname = "login";
      let method = "post";
      let body = {
        email: email,
        ip_address: '',
        login_name: name,
        type: loginType
      };
      try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
          if (response.code === 200) {
            //display timer on resend otp
            setTimerStart(0);
            if (response.default_otp) {
              setErrorMessage('');
              let message = 'Your default OTP is ' + response.default_otp;
              setSuccessMessage(message);
            } else {
              setErrorMessage('');
              let message = response.message;
              setSuccessMessage(message);
            }
          } else {
            setIsDisabled(false);
            setSuccessMessage('');
            let message = response.message;
            setErrorMessage(message);
          }
        }
        IsLoad(false);
      } catch (err) {
        console.log(err);
        setIsDisabled(false);
        IsLoad(false);
      }
    }
  }

  async function resendOTP() {
    IsLoad(true);
    let apiname = "resend_otp";
    let method = "post";
    let body = {
      email: email
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        if (response.code === 200) {
         
          //display timer on resend otp
          setTimerStart(0);
          if (response.default_otp !== '') {
            setErrorMessage('');
            let message = response.message + ' Your default otp is ' + response.default_otp;
            setSuccessMessage(message);
          } else {
            setErrorMessage('');
            let message = response.message;
            setSuccessMessage(message);
          }
        } else {
          setSuccessMessage('');
          let message = response.message;
          setErrorMessage(message);
        }
      }
      IsLoad(false);
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
  }

  function editEmail() {
    setSuccessMessage('');
    setErrorMessage('');
    setTimerStart(1);
    setIsDisabled(false);
  }

  // to verify OTP
  async function verifyOTP(inputOTP) {
    // event.preventDefault();
    IsLoad(true);
    let apiname = "login_otp_verify";
    let method = "post";
    let body = {
      email: email,
      otp: inputOTP,
      type: loginType
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if(response.code === 200) {
        localStorage.setItem('session_key', response.session_key);
        localStorage.setItem('type', loginType);
        if (response.isadmin !== '') {
          localStorage.setItem('isadmin', response.isadmin);
        }
        window.location.href = '/';
      } else {
        setSuccessMessage('');
        let message = response.message;
        setErrorMessage(message);
      }
      IsLoad(false);
    } catch (err) {
      console.log(err);
      IsLoad(false);
      setIsDisabled(false);
    }

  }
  function saveOTP(otp) {
    if(otp.length === 6) {
      verifyOTP(otp);
    } 
  }

  const loader = loading ? <Loader /> : null;
  return (
    <section className="contact-info pb-0">
      {loader}
      <div className="row">
        <div
          className="col-lg-6 col-md-6 order-2 order-md-1 login-image-style"
          style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        >
          <div className="client-login-head-wrapper">
            <div className="divider mb-3"></div>
            <h4 className="client-login-head">“You can know your <br />status in the <br />queue at any<br />time.”</h4>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 order-1 order-md-2">
          <div className="container d-flex align-items-center justify-content-center vh-100 custom-login-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  {successMessage.length?(
                    <SuccessComponent message={successMessage} />
                  ):(null)}
                  {errorMessage.length?(
                    <DangerComponent message={errorMessage} />
                  ):(null)}
                  <h2 className="cm-login-head">Log in with Email—No Registration Required.</h2>
                  <p className="cm-login-p">
                    Fill your details, Get the OTP Now!
                  </p>
                  <span id="message"></span>
                  <form onSubmit={!isDisabled?submitDetails:verifyOTP}>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fas fa-user"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control custom-input"
                        placeholder="Enter your name"
                        aria-label="Username"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        maxLength={100}
                        autoComplete='off'
                        disabled={isDisabled}
                        required
                      />
                    </div>
                    {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input
                        type="email"
                        className="form-control custom-input"
                        placeholder="Enter your email"
                        aria-label="Email"
                        onChange={(e) => setEmail(e.target.value.trim())}
                        value={email}
                        maxLength={100}
                        disabled={isDisabled}
                        autoComplete='off'
                        required
                      />
                    </div>
                    <div id="timer_div">
                      {timerStart == 0?(
                        <Timer minute={timerStart} second="59" resendOTP={resendOTP} submitOTP={saveOTP} editEmail={editEmail} />
                      ):(null)}
                    </div>
                    {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CATCHA_SITEKEY}
                      size="invisible"
                      ref={reCaptchaRef}
                    />
                    <div className="input-group">
                    {!isDisabled?(
                      <button type="submit" className="cu-cl-login">
                        {!isDisabled?'Login':'Submit'}
                      </button>   
                    ):(null)}                     
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CustomerLoginComponent;
