import Header from '../Components/header';
import Footer from '../Components/footer';

function TermsAndConditions() {
  return (
    <>
      <Header />
      
      <main className="privacy-policy-content">
        <h1>Terms and Conditions</h1>
        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to the Doctor Token Booking App. By using our app, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.
          </p>
          
          <h2>2. User Obligations</h2>
          <p>
            - Users must provide accurate and complete information when registering and booking appointments.
            - Users are responsible for maintaining the confidentiality of their account information.
            - Users must not use the app for any unlawful or prohibited activities.
          </p>
          
          <h2>3. Booking and Cancellation</h2>
          <p>
            - Users can book appointments with available doctors through the app.
            - Cancellations must be made at least 24 hours before the scheduled appointment time.
            - The app reserves the right to cancel or reschedule appointments due to unforeseen circumstances.
          </p>
          
          {/* <h2>4. Payment and Fees</h2>
          <p>
            - Payment for services must be made through the app using the available payment methods.
            - All fees are non-refundable unless otherwise stated.
          </p> */}
          
          <h2>4. Privacy and Data Protection</h2>
          <p>
            We are committed to protecting your privacy. Please refer to our Privacy Policy for more information on how we collect, use, and protect your personal data.
          </p>
          
          <h2>5. Limitation of Liability</h2>
          <p>
            - The app is provided on an "as is" basis. We do not guarantee the accuracy, completeness, or reliability of any information or services provided through the app.
            - We are not liable for any direct, indirect, incidental, or consequential damages arising from the use of the app.
          </p>
          
          <h2>6. Changes to Terms</h2>
          <p>
            We reserve the right to modify these terms and conditions at any time. Users will be notified of any changes through the app.
          </p>
          
          <h2>7. Contact Information</h2>
          <p>
            For any questions or concerns regarding these terms and conditions, please contact us at support@bookatoken.com.
          </p>
        </section>
      </main>
      
      <Footer />
    </>
  );
}

export default TermsAndConditions;