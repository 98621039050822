
import ShiftComponent from './shiftComponent';
import BookedComponent from './bookedComponent';
import React from 'react';

function DailyAvailabilityContent(data) {
    const customDataObject = data.customDataObject;
    let client_id = document.getElementById("client_id").value;
    return (
        <tbody id="dataShift">
            {customDataObject.m_available === "1" ?
                <ShiftComponent status={customDataObject.morning} token_count={customDataObject.m_token_count} flag={customDataObject.morning_flag} time_from={customDataObject.morning_time_from} time_to={customDataObject.morning_time_to} date={customDataObject.date} client_id={client_id} display_flag={customDataObject.m_display_flag} getBookingInfoByDate={data.getBookingInfoByDate} />
                : null
            }
            {customDataObject.a_available === "1" ?
                <ShiftComponent status={customDataObject.afternoon} token_count={customDataObject.a_token_count} flag={customDataObject.afternoon_flag} time_from={customDataObject.afternoon_time_from} time_to={customDataObject.afternoon_time_to} date={customDataObject.date} client_id={client_id} display_flag={customDataObject.a_display_flag} getBookingInfoByDate={data.getBookingInfoByDate} />
                : null
            }
            {customDataObject.e_available === "1" ?
                <ShiftComponent status={customDataObject.evening} token_count={customDataObject.e_token_count} flag={customDataObject.evening_flag} time_from={customDataObject.evening_time_from} time_to={customDataObject.evening_time_to} date={customDataObject.date} client_id={client_id} display_flag={customDataObject.e_display_flag} getBookingInfoByDate={data.getBookingInfoByDate} />
                : null
            }
        </tbody>
    );

}
export default DailyAvailabilityContent;
