import Header from '../Components/header';
import Footer from '../Components/footer';
import '../App.css';

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <p>Last updated: January 30, 2025</p>
        
        <p>
          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.
        </p>
        
        <h2>Interpretation and Definitions</h2>
        
        <h3>Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>
        
        <h3>Definitions</h3>
        <ul>
          <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
          <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
          <li><strong>Application</strong> refers to Naazhika Guru, the software program provided by the Company.</li>
          <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Obidos Technologies (P) Ltd, C1-10, Chaitanya Building, Infopark, Cherthala.</li>
          <li><strong>Country</strong> refers to: Kerala, India</li>
          <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
          <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
          <li><strong>Service</strong> refers to the Application.</li>
          <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
          <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
          <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
        </ul>
        
        <h2>Collecting and Using Your Personal Data</h2>
        
        <h3>Types of Data Collected</h3>
        
        <h4>Personal Data</h4>
        <p>
          While using Our Service, We don't collect any personally identifiable information that can be used to contact or identify You. We collect the location information from the device so that we can provide you with accurate information of sun rise time. The location information collected is sent anonymously to a 3rd party server to get the sun rise time.
        </p>
        
        <h4>Usage of Data</h4>
        <p>
          Information Collected while Using the Application
        </p>
        <p>
          We do not store any location information other than to identify the location to show the relevant information. You can enable or disable access to this information at any time, through Your Device settings.
        </p>
        
        <h4>Usage of Personal Data</h4>
        <p>
          The Company does not collect, use or share any Personal Data.
        </p>
        
        <h2>Children's Privacy</h2>
        <p>
          Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers. If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
        </p>
        
        <h2>Link to Other Websites</h2>
        <p>
          Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
        </p>
        
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;