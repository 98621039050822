import React, { useEffect, useState } from 'react';
import Loader from './loader';
import SuccessComponent from './successComponent';
import DangerComponent from './dangerComponent';
import RestHelper from '../RestHelper';
import ReCAPTCHA from "react-google-recaptcha";

function DoctorRegistrationComponent() {
  const [loading, IsLoad] = useState(false);
  const [errors, setErrors] = useState({});
  
  // state for client registration
  const [client_name, setCName] = useState('');
  const [client_address, setCAddress] = useState('');
  const [client_city, setCCity] = useState('');
  const [client_state, setCState] = useState('');
  const [client_country, setCCountry] = useState('');
  const [client_phone, setCPhone] = useState('');
  const [client_email, setCEmail] = useState('');
  const [client_category, setCCategory] = useState('');
  const [client_specialization, setCSpecialization] = useState('');
  const [client_admin, setCAdmin] = useState('0');

  // states for selectbox
  const [categories, setCategories] = useState('');
  const [specializations, setSpecializations] = useState('');
  const [countries, setCCountries] = useState('');
  const [states, setStates] = useState('');
  const [cities, setCities] = useState('');


  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const backgroundImageUrl = process.env.REACT_APP_IMAGE_URL + 'client_register_bg.png';

  const reCaptchaRef = React.useRef();

  useEffect(() => {
    getCategories();
    async function getCategories() {
      IsLoad(true);
      let apiname = "getCategories";
      let method = "post";
      let body = {
        date: ''
      };
      try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
          response = response.result;
          let selectArray = [];
          for (var i = 0; i < response.length; i++) {
            let id = response[i].job_slug;
            let label = response[i].job_name; 
            selectArray.push({id:id,label:label});
            setCCategory(response[0].job_slug);
          }
          setCategories(selectArray);
          getSubCategories(response[0].job_id);
          setCCategory(response[0].job_slug);
        }
      } catch (err) {
        console.log(err);
      }
    }
    getCountries();
  }, []);
  async function getCountries() {
    let apiname = "getCountry";
      let method = "get";
      let body = {
        date: ''
      };
      try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
          response = response.result;
          let selectArray = [];
          for (var i = 0; i < response.length; i++) {
            let id = response[i].id;
            let label = response[i].name; 
            selectArray.push({id:id,label:label});
            setCCountry(response[0].id);
          }
          setCCountries(selectArray);
          getStates(response[0].id);
        }
      } catch (err) {
        console.log(err);
      }
  }
  async function getStates(countryid) {
    let apiname = "getStates";
      let method = "post";
      let body = {
        country_id: countryid
      };
      try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
          response = response.result;
          let selectArray = [];
          for (var i = 0; i < response.length; i++) {
            let id = response[i].id;
            let label = response[i].name; 
            selectArray.push({id:id,label:label});
            setCState(response[0].id);
          }
          setStates(selectArray);
          getCities(response[0].id);
        }
      } catch (err) {
        console.log(err);
      }
  }
  async function getCities(stateid) {
    let apiname = "getCities";
      let method = "post";
      let body = {
        state_id: stateid
      };
      try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
          response = response.result;
          let selectArray = [{id:'',label:'Select City'}];
          for (var i = 0; i < response.length; i++) {
            let id = response[i].id;
            let label = response[i].name; 
            selectArray.push({id:id,label:label});
          }
          setCities(selectArray);
        }
        IsLoad(false);
      } catch (err) {
        IsLoad(false);
        console.log(err);
      }
  }

  async function getSubCategories(catid) {
    let apiname = "getSpecialization";
    let method = "post";
    let body = {
      category: catid
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        response = response.result;
        let selectArray = [{id:'',label:'Select Specialization'}];
        for (var i = 0; i < response.length; i++) {
          let id = response[i].job_specialization_id;
          let label = response[i].specialization; 
          selectArray.push({id:id,label:label});
        }
        setSpecializations(selectArray);
        IsLoad(false);
      }
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
  }

  function validateForm() {
    const errors = {};
    setCName(client_name.trim());
    setCAddress(client_address.trim());
    setCEmail(client_email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(client_name.length == 0) {
      errors.client_name = 'Name is required';
    }
    if (!client_email) {
      errors.client_email = 'Email is required';
    } else if (!emailRegex.test(client_email)) {
      errors.client_email = 'Invalid email format';
    }
    if(client_address.length == 0) {
      errors.client_address = 'Address is required';
    }
    if(client_phone.length < 10 || client_phone.length > 10) {
      errors.client_phone = 'Invalid phone number';
    }
    setErrors(errors);

    return Object.keys(errors).length === 0;
  }

  async function submitDetails(event) {
    event.preventDefault();
    if (validateForm()) {

      // You can access the reCAPTCHA token using reCaptchaRef
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();

      setIsDisabled(true);
      IsLoad(true);
      let apiname = "client_register";
      let method = "post";
      let body = {
        phone: client_phone,
          name: client_name,
          email: client_email,
          address: client_address,
          city: client_city,
          state: client_state,
          country: client_country,
          category: client_category,
          subcategory: client_specialization,
          is_admin: client_admin
      };
      try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
          setIsDisabled(false);
          IsLoad(false);
          window.scrollTo(0,0);
          if (response.code === 200) {
              setErrorMessage('');
              let message = response.message;
              setSuccessMessage(message);
              resetState();
          } else {
              setSuccessMessage('');
              let message = response.message;
              setErrorMessage(message);
          }
        }
      } catch (err) {
        console.log(err);
        setIsDisabled(false);
        IsLoad(false);
      }
    }
  }

  function resetState() {
    setCName('');
    setCAddress('');
    setCCity('');
    setCPhone('');
    setCEmail('');
    setCSpecialization('');
  }

  const handleCustomerPhoneChange = (e) => {
    const value = e.target.value;
    const format = /^[0-9]*$/;
    if (format.test(value) && value.length <= 10) {
      setCPhone(value);
    }
  };


  const loader = loading ? <Loader /> : null;
  return (
    <section className="contact-info pb-0">
      {loader}
      <div className="row">
        <div
          className="col-lg-6 col-md-6 order-2 order-md-1 login-image-style"
          style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        >
          <div className="client-login-head-wrapper">
            <div className="divider mb-3"></div>
            <h4 className="client-login-head">“Stay informed <br />about your appointments <br />and patient status <br />throughout <br />the day.”</h4>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 order-1 order-md-2">
          <div className="container d-flex align-items-center justify-content-center custom-login-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  {successMessage.length?(
                    <SuccessComponent message={successMessage} />
                  ):(null)}
                  {errorMessage.length?(
                    <DangerComponent message={errorMessage} />
                  ):(null)}
                  <h2 className="cm-login-head">Doctor's Registration</h2>
                  <p className="cm-login-p">
                  Please fill in the requested information below, and we will contact you soon!
                  </p>
                  <span id="message"></span>
                  <form onSubmit={submitDetails}>
                   
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa fa-user"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control custom-input"
                        placeholder="Name"
                        aria-label="Name"
                        onChange={(e) => setCName(e.target.value)}
                        value={client_name}
                        maxLength={100}
                        required
                      />
                    </div>
                    {errors.client_name && <span style={{ color: 'red' }}>{errors.client_name}</span>}
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input
                        type="email"
                        className="form-control custom-input"
                        placeholder="Email"
                        aria-label="Email"
                        onChange={(e) => setCEmail(e.target.value.trim())}
                        value={client_email}
                        maxLength={100}
                        required
                      />
                    </div>
                    {errors.client_email && <span style={{ color: 'red' }}>{errors.client_email}</span>}
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control custom-input"
                        placeholder="Address"
                        aria-label="Address"
                        onChange={(e) => setCAddress(e.target.value)}
                        value={client_address}
                        maxLength={200}
                        required
                      />
                    </div>
                    {errors.client_address && <span style={{ color: 'red' }}>{errors.client_address}</span>}
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fas fa-location-arrow"></i>
                      </span>
                      <select
                        className="form-control custom-input"
                        aria-label=" Country"
                        onChange={
                          (e) => {
                            setCCountry(e.target.value);
                            getStates(e.target.value);
                          }
                        }
                        value={client_country}
                        required
                      >
                        {countries.length > 0 && countries.map((country, idx) => (
                          <option value={country.id} key={idx}>{country.label}</option>
                        ))}
                      </select>
                    </div>
                    {errors.client_country && <span style={{ color: 'red' }}>{errors.client_country}</span>}
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fas fa-flag"></i>
                      </span>
                      <select
                        type="text"
                        className="form-control custom-input"
                        aria-label="State"
                        onChange={
                          (e) => {
                            setCState(e.target.value);
                            getCities(e.target.value);
                          }
                        }
                        value={client_state}
                        required
                      >
                        {states.length > 0 && states.map((sts, idx) => (
                          <option value={sts.id} key={idx}>{sts.label}</option>
                        ))}
                      </select>
                    </div>
                    {errors.client_state && <span style={{ color: 'red' }}>{errors.client_state}</span>}
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fas fa-map"></i>
                      </span>
                      <select
                        type="text"
                        className="form-control custom-input"
                        aria-label="City"
                        onChange={(e) => setCCity(e.target.value)}
                        value={client_city}
                        required
                      >
                        {cities.length > 0 && cities.map((cts, idx) => (
                          <option key={idx} value={cts.id}>{cts.label}</option>
                        ))}
                      </select>
                    </div>
                    {errors.client_city && <span style={{ color: 'red' }}>{errors.client_city}</span>}
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fas fa-phone"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control custom-input"
                        placeholder="Phone"
                        aria-label="City"
                        onChange={handleCustomerPhoneChange}
                        value={client_phone}
                        maxLength={10}
                        autoComplete='off'
                        required
                      />
                    </div>
                    {errors.client_phone && <span style={{ color: 'red' }}>{errors.client_phone}</span>}
                    {/* <div className="input-group">
                      <span className="input-group-text">
                        <i className="fas fa-plus"></i>
                      </span>
                      <select
                        className="form-control custom-input"
                        aria-label="Category"
                        onChange={
                          (e) => {
                            setCCategory(e.target.value);
                            setCSpecialization('');
                            getSubCategories(e.target.value);
                          }
                        }
                        value={client_category}
                        required
                      >
                      {categories.length > 0 && categories.map((cts, idx) => (
                        <option key={idx} value={cts.id}>{cts.label}</option>
                      ))}
                      </select>
                    </div>
                    {errors.client_category && <span style={{ color: 'red' }}>{errors.client_category}</span>} */}
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fas fa-stethoscope"></i>
                      </span>
                      <select
                        className="form-control custom-input"
                        aria-label="Specialization"
                        onChange={(e) => setCSpecialization(e.target.value)}
                        value={client_specialization}
                        required
                      >
                      {specializations.length > 0 && specializations.map((sps, idx) => (
                        <option key={idx} value={sps.id}>{sps.label}</option>
                      ))}
                      </select>
                    </div>
                    {errors.client_specialization && <span style={{ color: 'red' }}>{errors.client_specialization}</span>}
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CATCHA_SITEKEY}
                      size="invisible"
                      ref={reCaptchaRef}
                    />
                    <div className="input-group">
                      <button type="submit" className="cu-cl-login" disabled={isDisabled}>
                        Submit
                      </button>   
                    </div>
                    <div className='row'>
                      <div className='col-md-12' align="right" style={{marginTop: 13, display: 'flex'}}>
                        <p>Already registered ?&nbsp;
                        <a
                          className="resent-btn-text client-register-link"
                          onClick={()=> { window.location.href = '/client' }}
                        >Login Now</a>  
                        </p>
                        
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DoctorRegistrationComponent;
