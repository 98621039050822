import { useState } from "react";
import HomeBookings from "./homeBookings";
import RestHelper from "../RestHelper";

function Content() {
  const [type,setType]   = useState('');
  if(localStorage.getItem('session_key')) { 
   getProfileData();
  }
  async function getProfileData() {
    let apiname = "getProfileInfo";
    let method = "post";
    let body = {
      date: ''
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        if (response.code === 200) {
          let result = response.result;
          setType(result.type);
        } 
      }
    } catch (err) {
      console.log(err);
    }
  }
  function showPop() {
    window.location.href='/customer';
  }
    return (
      <>
        <section className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-xl-7">
                <div className="block">
                  <div className="divider mb-3"></div>
                  <span className="text-uppercase text-sm letter-spacing ">Best queue management system</span>
                  <h1 className="mb-3 mt-3">Real-time queue information</h1>
                  
                  <p className="mb-4 pr-5">You can know your status in the queue at any time. <br />No need to wait indefinitely.</p>
                  <div className="btn-container ">
                  {localStorage.getItem('type') === '1'? (
                    <a href="/tokeninfo" className="btn btn-main-2 btn-icon btn-round-full">Your Bookings<i className="icofont-simple-right ml-2  "></i></a>
                  ):(
                    <a href="/search" className="btn btn-main-2 btn-icon btn-round-full">Book a Token<i className="icofont-simple-right ml-2  "></i></a>
                  )}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="features">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="feature-block d-lg-flex">
                  <div className="feature-item mb-5 mb-lg-0">
                    <div className="feature-icon mb-4">
                      <i className="icofont-surgeon-alt"></i>
                    </div>
                    <span>24 Hours Service</span>
                    <h4 className="mb-3">Online token Booking</h4>
                    <p className="mb-4">Book a token at your convenince anytime.</p>
                    {localStorage.getItem('type') === '1'? (
                    <a href="/tokeninfo" className="btn btn-main-2 btn-icon btn-round-full">Your Bookings<i className="icofont-simple-right ml-2  "></i></a>
                  ):(
                    <a href="/search" className="btn btn-main-2 btn-icon btn-round-full">Book a Token<i className="icofont-simple-right ml-2  "></i></a>
                  )}
                  </div>
                
                  <div className="feature-item mb-5 mb-lg-0">
                    <div className="feature-icon mb-4">
                      <i className="icofont-support"></i>
                    </div>
                    <span>Contact us</span>
                    <h4 className="mb-3"><a href="tel:+918921653212">+91 8921653212</a></h4>
                    <p>Call us if you need any assistance.</p>
                  </div>

                  <div className="feature-item mb-5 mb-lg-0" id="home_booking">
                      <HomeBookings type="type"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="section about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-sm-6">
                <div className="about-img">
                  <img src="assets/images/about/img-1.png" alt="" className="img-fluid" />
                  <img src="assets/images/about/img-2.jpg" alt="" className="img-fluid mt-4" />
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="about-img mt-4 mt-lg-0">
                  <img src="assets/images/about/img-3.jpg" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="about-content pl-4 mt-4 mt-lg-0">
                  <h2 className="title-color">Quick and easy <br />token booking</h2>
                  <p className="mt-4 mb-5">We are continuously adding more service providers to our system. Soon, you will be able to book a token based on their availability. </p>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="cta-section ">
          <div className="container">
            <div className="cta position-relative">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="counter-stat">
                    <i className="icofont-doctor"></i>
                    <span className="h3">58</span>k
                    <p>Happy People</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="counter-stat">
                    <i className="icofont-flag"></i>
                    <span className="h3">700</span>+
                    <p>Surgery Comepleted</p>
                  </div>
                </div>
                
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="counter-stat">
                    <i className="icofont-badge"></i>
                    <span className="h3">40</span>+
                    <p>Expert Doctors</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="counter-stat">
                    <i className="icofont-globe"></i>
                    <span className="h3">20</span>
                    <p>Worldwide Branch</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> 
        <section className="section service gray-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 text-center">
                <div className="section-title">
                  <h2>Award winning patient care</h2>
                  <div className="divider mx-auto my-4"></div>
                  <p>Lets know moreel necessitatibus dolor asperiores illum possimus sint voluptates incidunt molestias nostrum laudantium. Maiores porro cumque quaerat.</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-laboratory text-lg"></i>
                    <h4 className="mt-3 mb-3">Laboratory services</h4>
                  </div>

                  <div className="content">
                    <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-heart-beat-alt text-lg"></i>
                    <h4 className="mt-3 mb-3">Heart Disease</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-tooth text-lg"></i>
                    <h4 className="mt-3 mb-3">Dental Care</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                  </div>
                </div>
              </div>


              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-crutch text-lg"></i>
                    <h4 className="mt-3 mb-3">Body Surgery</h4>
                  </div>

                  <div className="content">
                    <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-brain-alt text-lg"></i>
                    <h4 className="mt-3 mb-3">Neurology Sargery</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-dna-alt-1 text-lg"></i>
                    <h4 className="mt-3 mb-3">Gynecology</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}
        {/* <section className="section appoinment">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 ">
                <div className="appoinment-content">
                  <img src="assets/images/about/img-3.jpg" alt="" className="img-fluid" />
                  <div className="emergency">
                    <h2 className="text-lg"><i className="icofont-phone-circle text-lg"></i>+23 345 67980</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-10 ">
                <div className="appoinment-wrap mt-5 mt-lg-0">
                  <h2 className="mb-2 title-color">Book A Token</h2>
                  <p className="mb-4">Search for a service provider and check their availability to book a token.</p>
                      <form id="/" className="appoinment-form" method="post" action="/">
                           <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <select className="form-control" id="exampleFormControlSelect1">
                                          <option>Choose Department</option>
                                          <option>Software Design</option>
                                          <option>Development cycle</option>
                                          <option>Software Development</option>
                                          <option>Maintenance</option>
                                          <option>Process Query</option>
                                          <option>Cost and Duration</option>
                                          <option>Modal Delivery</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <select className="form-control" id="exampleFormControlSelect2">
                                          <option>Select Doctors</option>
                                          <option>Software Design</option>
                                          <option>Development cycle</option>
                                          <option>Software Development</option>
                                          <option>Maintenance</option>
                                          <option>Process Query</option>
                                          <option>Cost and Duration</option>
                                          <option>Modal Delivery</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input name="date" id="date" type="text" className="form-control" placeholder="dd/mm/yyyy" />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input name="time" id="time" type="text" className="form-control" placeholder="Time" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input name="name" id="name" type="text" className="form-control" placeholder="Full Name" />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input name="phone" id="phone" type="Number" className="form-control" placeholder="Phone Number" />
                                    </div>
                                </div>
                            </div> 
                            <div className="form-group-2 mb-4">
                                <textarea name="message" id="message" className="form-control" rows="6" placeholder="Your Message"></textarea>
                            </div>
                            
                        </form>
                    </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="section testimonial-2 gray-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="section-title text-center">
                  <h2>Testimonial</h2>
                  <div className="divider mx-auto my-4"></div>
                  <p>Encouraging feedback from our users.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 testimonial-wrap-2">
                <div className="testimonial-block style-2  gray-bg">
                  <i className="icofont-quote-right"></i>

                  <div className="testimonial-thumb">
                    <img src="assets/images/team/test-thumb1.jpg" alt="" className="img-fluid" />
                  </div>

                  <div className="client-info ">
                    <h4>Amazing service!</h4>
                    <span>John Martin</span>
                    <p>
                      This is a great service. Congratulations to the team.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
         <section className="section clients">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="section-title text-center">
                  <h2>About BookaToken.com</h2>
                  <div className="divider mx-auto my-4"></div>
                  <p>
              Powered by<br /> <h4 className="mb-3">Obidos Technologies (P) Ltd</h4>Office No: C1-10, C-Wing, First Floor,<br />
     Chaitanya Building, Infopark,<br/>
     K.R.Puram P.O., Cherthala-688556<br/>
     Alappuzha, Kerala, INDIA
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> 
        <div id="modalAppend"></div>
      </>
    );
}
export default Content;