import Header from '../Components/header';
import Footer from '../Components/footer';
import '../App.css';

function FAQ() {
  return (
    <>
      <Header />
      
      <main className="faq-content">
        <h1 className="faq-title">Frequently Asked Questions</h1>
        <section className="faq-section">
          <h2 className="faq-question">1. What is Book a Token?</h2>
          <p className="faq-answer">
            Book a Token is a web application that allows users to book appointments with doctors easily and efficiently. It provides a seamless experience for managing your healthcare appointments.
          </p>
          
          <h2 className="faq-question">2. How do I create an account?</h2>
          <p className="faq-answer">
            To create an account, visit the Book a Token website and follow the on-screen instructions to register using your email address.
          </p>
          
          <h2 className="faq-question">3. How can I book an appointment?</h2>
          <p className="faq-answer">
            Once logged in, navigate to the 'Book Appointment' section, select your preferred doctor and available time slot, and confirm your booking.
          </p>
          
          <h2 className="faq-question">4. Can I cancel or reschedule an appointment?</h2>
          <p className="faq-answer">
            Yes, you can cancel or reschedule an appointment through the app. Please note that cancellations must be made at least 24 hours before the scheduled time.
          </p>
          
          <h2 className="faq-question">5. How is my personal data protected?</h2>
          <p className="faq-answer">
            We take your privacy seriously. Your personal data is encrypted and stored securely. Please refer to our Privacy Policy for more details.
          </p>
          
          <h2 className="faq-question">6. Who can I contact for support?</h2>
          <p className="faq-answer">
            If you have any questions or need assistance, please contact our support team at <a href="mailto:support@bookatoken.com">support@bookatoken.com</a>.
          </p>
        </section>
      </main>
      
      <Footer />
    </>
  );
}

export default FAQ;