/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-redeclare */
import SuccessComponent from './successComponent';
import DangerComponent from './dangerComponent';
import ReactDOM from 'react-dom';
import React,{ Component } from 'react';
import moment from 'moment';
import Loader from './loader';
import RestHelper from '../RestHelper';

// minimum date setting
let minDate = moment().format('YYYY-MM-DD');
let maxDate = moment().add(30, 'days').format('YYYY-MM-DD');
//  Dateset();
//  function Dateset() {
//     var today = new Date().toISOString();
//     let to_day = moment(today).format('YYYY-MM-DD');
//  }


function sessionDestroy() {
    localStorage.removeItem('session_key');
    localStorage.removeItem('type');
    window.location.href="/";
  }

function checkAtLeastOne() {
    const row = document.getElementById('checkAtLeastOne');
    const inputElements = Array.from(row.querySelectorAll(['input[type=time]','input[type=number]']));
        const validInputs = inputElements.filter(el => el.value);
        if (!validInputs.length) {
                return false;
         } else {
               return true; 
         }
}
function isTimeValid(start, end) {
    let start_time = document.getElementById(start).value;
    let end_time = document.getElementById(end).value;
    ReactDOM.unmountComponentAtNode(document.getElementById('message_daily'));

    if (start_time !== '' && end_time !== '') {
        // Parse the time strings into moment objects for comparison
        // Use 'hh:mm A' format to include AM/PM
        let startMoment = moment(start_time, 'hh:mm A');
        let endMoment = moment(end_time, 'hh:mm A');

        if (startMoment.isBefore(endMoment)) {
            document.getElementById("message_daily").innerHTML = '';
            return true;
        } else {
            window.scrollTo(0, 0);
            var message = 'Start time should be less than end time';
            ReactDOM.render(<DangerComponent message={message} />, document.getElementById("message_daily"));
            return false;
        }
    }
}

// window.addEventListener('load', (event) => {
//     getAvailabilityByDate();
//   });
class DailyAvailabilityComponent extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            date: minDate,
            m_s_1: '',
            m_e_1: '',
            m_x_1: '',
            m_c_1: '',
            a_s_1: '',
            a_e_1: '',
            a_x_1: '',
            a_c_1: '',
            e_s_1: '',
            e_e_1: '',
            e_x_1: '',
            e_c_1: '',
            date_err: '',
            m_s_1_err: '',
            m_e_1_err: '',
            m_x_1_err: '',
            a_s_1_err: '',
            a_e_1_err: '',
            a_x_1_err: '',
            e_s_1_err: '',
            e_e_1_err: '',
            e_x_1_err: '',

        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.getAvailabilityByDate(minDate);
    }
     ResetFields = (idx,m_a_e) => {
        if(m_a_e === 'm') { 
            this.setState({ m_s_1: '', m_e_1: '', m_x_1: '', m_c_1: '' });
        } else if(m_a_e === 'a') {
            this.setState({ a_s_1: '', a_e_1: '', a_x_1: '', a_c_1: '' });
        }else {
            this.setState({ e_s_1: '', e_e_1: '', e_x_1: '', e_c_1: '' });
        }
    }
    getAvailabilityByDate = async (date) => {
        this.setState({ loading: true});
        let session_key = localStorage.getItem('session_key');
        let apiname = "getAvailabilityByDate";
        let method = "post";
        let body = {
            date: date
        };
        try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
            this.ResetFields(1,'m');
            this.ResetFields(1,'a');
            this.ResetFields(1,'e');
            let dataByDate = response.result;
            if(dataByDate[0].m_available === "1") {
                this.setState({ 
                    m_s_1: dataByDate[0].morning_start,
                    m_e_1: dataByDate[0].morning_end,
                    m_x_1: dataByDate[0].m_maxtoken,
                    m_c_1: 1
                });
            }
            if(dataByDate[0].a_available === "1") {
                this.setState({ 
                    a_s_1: dataByDate[0].afternoon_start,
                    a_e_1: dataByDate[0].afternoon_end,
                    a_x_1: dataByDate[0].a_maxtoken,
                    a_c_1: 1
                });
            }
            if(dataByDate[0].e_available === "1") {
                this.setState({ 
                    e_s_1: dataByDate[0].evening_start,
                    e_e_1: dataByDate[0].evening_end,
                    e_x_1: dataByDate[0].e_maxtoken,
                    e_c_1: 1
                });
            }
            this.setState({ loading: false});
        }
        } catch (err) {
            this.setState({ loading: false});
        }
         
    };
    handleValidation = (idx, m_a_e, start, end, max) => {
        const first = `${m_a_e}_s_${idx}`;
        const firsterror = `${m_a_e}_s_${idx}_err`;
        if (!document.getElementById(first).value) {
            this.setState({ [firsterror]: 'Field is required' });
            return false;
        }
        const second = `${m_a_e}_e_${idx}`;
        const seconderror = `${m_a_e}_e_${idx}_err`;
        if (!document.getElementById(second).value) {
            this.setState({ [seconderror]: 'Field is required' });
            return false;
        }
        const third = `${m_a_e}_x_${idx}`;
        const thirderror = `${m_a_e}_x_${idx}_err`;
        if (!document.getElementById(third).value) {
            this.setState({ [thirderror]: 'Field is required' });
            return false;
        }
        return true;
    }
    InnerHtmlClear = () => {   
        this.setState({
            date_err: '',
            m_s_1_err: '',
            m_e_1_err: '',
            m_x_1_err: '',
            a_s_1_err: '',
            a_e_1_err: '',
            a_x_1_err: '',
            e_s_1_err: '',
            e_e_1_err: '',
            e_x_1_err: '' 
        });    
        return true;
        
    }
    DailyAvailabilitySetting = async () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('message_daily'));
        let date = this.state.date;

        //clear all message showing span elements
        this.InnerHtmlClear();
        if(date === "") {
            this.setState({
                date_err: 'Field is required'
            });
            return false;
        }
        let m_available   = 0;
        let morning_start = this.state.m_s_1;
        if(morning_start === '') {
            morning_start = null;
        } 
        let morning_end   = this.state.m_e_1;
        if(morning_end === '') {
            morning_end = null;
        }
        let m_maxtoken    = this.state.m_x_1;
        if(m_maxtoken === '') {
            m_maxtoken = 0;
        }
        //check validation of morning shift
        if(morning_start !== null || morning_end !== null || m_maxtoken !== 0) {
            document.getElementById("m_c_1").checked = true;
            m_available =1;
            var returnEvent = this.handleValidation('1','m',morning_start,morning_end,m_maxtoken);
            if(returnEvent === true) {
                let time1 = isTimeValid("m_s_1","m_e_1");
                if(time1 === false) {
                    return false;
                }
            } else {
                return false;
            }
        }
    
        let a_available     = 0;
        let afternoon_start = this.state.a_s_1;
        if(afternoon_start === '') {
            afternoon_start = null;
        }
        let afternoon_end   = this.state.a_e_1;
        if(afternoon_end === '') {
            afternoon_end = null;
        }
        let a_maxtoken      = this.state.a_x_1;
        if(a_maxtoken === '') {
            a_maxtoken = 0;
        }
        //check validation of afternoon shift
        if(afternoon_start !== null || afternoon_end !== null || a_maxtoken !== 0) {
            document.getElementById("a_c_1").checked = true;
            a_available = 1;
            var returnEvent = this.handleValidation('1','a',afternoon_start,afternoon_end,a_maxtoken);
            if(returnEvent === true) {
                let time2 = isTimeValid("a_s_1","a_e_1");
                if(time2 === false) {
                    return false;
                }
            } else {
                return false;
            }
        }
    
        let e_available   = 0;
        let evening_start = this.state.e_s_1;
        if(evening_start === '') {
            evening_start = null;
        }
        let evening_end   = this.state.e_e_1;
        if(evening_end === '') {
            evening_end = null;
        }
        let e_maxtoken    = this.state.e_x_1;
        if(e_maxtoken === '') {
            e_maxtoken = 0;
        }
        //check validation of evening shift
        if(evening_start !== null || evening_end !== null || e_maxtoken !== 0) {
            document.getElementById("e_c_1").checked = true;
            e_available = 1;
            var returnEvent = this.handleValidation('1','e',evening_start,evening_end,e_maxtoken);
            if(returnEvent === true) {
                let time3 = isTimeValid("e_s_1","e_e_1");
                if(time3 === false) {
                    return false;
                }
            } else {
                return false;
            }
        }
        //check atleast one value
        var returnAtleaseData = checkAtLeastOne();
        if(returnAtleaseData === false) {
            var message = 'Please choose a shift';
            ReactDOM.render(<DangerComponent message={message} />,document.getElementById("message_daily"));
            return false;
        } 
        let client_session = localStorage.getItem('session_key');

				// for checking slot time matches
        var slotcheckresponse = this.CheckDailySlot(1);
				if(slotcheckresponse === false) {
					let message = 'There is a conflict with the entered time';
					ReactDOM.render(<DangerComponent message={message} />,document.getElementById("message_daily"));
					return false;
				}

        this.setState({ 
            loading: true
        });
        let apiname = "availability";
        let method = "post";
        let body = {
            date: date,
            m_available: m_available,
            morning_start: morning_start,
            morning_end: morning_end,
            m_maxtoken: m_maxtoken,

            a_available: a_available,
            afternoon_start: afternoon_start,
            afternoon_end: afternoon_end,
            a_maxtoken: a_maxtoken,

            e_available: e_available,
            evening_start: evening_start,
            evening_end: evening_end,
            e_maxtoken:e_maxtoken,            
        };
        try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
            this.setState({ 
                loading: false
            });
            if(response.code === 200)
            {
                window.scrollTo(0,0);
                let message = response.message;
                ReactDOM.render(<SuccessComponent message={message} />,document.getElementById("message_daily"));
            } else if(response.code === 401) {
                let message = response.message;
                ReactDOM.render(<DangerComponent message={message} />,document.getElementById("message_daily"));
                sessionDestroy();
            } else {
                window.scrollTo(0,0);
                let message = response.message;
                ReactDOM.render(<DangerComponent message={message} />,document.getElementById("message_daily"));
            }
        }
        } catch (err) {
            this.setState({loader: false});
        }
    
    }

		// for checking slot time matches
    CheckDailySlot = (sts) => {
				if(this.state.m_s_1 !== '' && this.state.a_s_1 !== '' && this.state.e_s_1 !== '') {
					if(this.state.m_e_1 >= this.state.a_s_1) {
						return false;						
					} else if(this.state.a_e_1 >= this.state.e_s_1) {
						return false;
					} else {
						return true;
					}
				} else if(this.state.m_s_1 !== '' && this.state.a_s_1 !== '' && this.state.e_s_1 === '') {
					if(this.state.m_e_1 >= this.state.a_s_1) {
						return false;
					} else {
						return true;
					}
				} else if(this.state.m_s_1 === '' && this.state.a_s_1 !== '' && this.state.e_s_1 !== '') {
					if(this.state.a_e_1 >= this.state.e_s_1) {
						return false;
					} else {
						return true;
					}
				} else if(this.state.m_s_1 !== '' && this.state.a_s_1 === '' && this.state.e_s_1 !== '') {
					if(this.state.m_e_1 >= this.state.e_s_1) {
						return false;
					}  else {
						return true;
					}
				} else {
					return true;
				}
    }

    //delete availability
    DeleteAvailability = async () => {
      ReactDOM.unmountComponentAtNode(document.getElementById('message_daily'));
        let date = this.state.date;
        //clear all message showing span elements
        this.InnerHtmlClear();
        if(date === "") {
            this.setState({
                date_err: 'Field is required'
            });
            return false;
        }
       
        this.setState({ 
            loading: true
        });
        let apiname = "deleteDailyAvailability";
        let method = "post";
        let body = {
            date: date
        };
        try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
            this.setState({ 
                loading: false
            });
            this.ResetFields('1','m');
            this.ResetFields('1','a');
            this.ResetFields('1','e');
            if(response.code === 200)
            {
                window.scrollTo(0,0);
                let message = response.message;
                ReactDOM.render(<SuccessComponent message={message} />,document.getElementById("message_daily"));
            } else if(response.code === 401) {
                let message = response.message;
                ReactDOM.render(<DangerComponent message={message} />,document.getElementById("message_daily"));
               
            } else {
                window.scrollTo(0,0);
                let message = response.message;
                ReactDOM.render(<DangerComponent message={message} />,document.getElementById("message_daily"));
            }
        }
        } catch (err) {
            this.setState({loader: false});
        }
        
    }

    handleChange(event) {
        const {name, value, type, checked} = event.target;
        type === "checkbox"? this.setState({ [name]: checked }): this.setState({ [name]: value });
    }
   render() { 
    const loader = this.state.loading?<Loader />:null;
    return (
        <>
        <section className="page-title bg-1">
        {loader}
        {/* {this.state.loader}?<div className="row d-flex h-100 overlayload"> <div className="col-md-12 justify-content-center align-self-center" style={{padding:"10px",color:"white",textAlign:"center"}}><i className="fa fa-spinner fa-spin" style={{fontSize:"40px"}}></i></div> </div>:null; */}
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block text-center">
                    <span className="text-white">Set your availability</span>
                    <h1 className="text-capitalize mb-5 text-lg">Daily Availability</h1>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <section className="appoinment section">
            <div className="container">
                <div className="row">
                <div className="col-lg-1">
                    {/* <div className="mt-3">
                        <div className="feature-icon mb-3">
                        <i className="icofont-support text-lg"></i>
                        </div>
                        <span className="h3">Call for an Emergency Service!</span>
                        <h2 className="text-color mt-3">+84 789 1256 </h2>
                    </div> */}
                </div>

                <div className="col-lg-12">
                    <div className="appoinment-wrap mt-5 mt-lg-0 pl-lg-5">
                        <h2 className="mb-2 title-color">Set Availability</h2>
                        <div id="message_daily">
                           
                        </div>
                        <form id="#" className="appoinment-form" method="post" action="#">
                                <div className="row">
                                   <div className="col-lg-12">
                                        <div className="form-group">
                                            <input name="date" type="date" className="form-control" id="date" min={minDate} max={maxDate} onChange={e => { this.handleChange(e); this.getAvailabilityByDate(e.target.value); }} value={this.state.date}/>
                                        </div>
                                        <span style={{color:"red"}} id="date_err">{this.state.date_err}</span>
                                    </div>
                                </div>
                                <div className="row" id="checkAtLeastOne">
                                    <div className="col-md-8">
                                      <div className="row">
                                        <div className="col-lg-1 justify-content-center d-flex flex-column">
                                             <input type="checkbox" className="check-class" id="m_c_1" name="m_c_1" checked={this.state.m_c_1} onChange={ e => this.handleChange(e) } value="1" />
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label>Morning Start</label>
                                                <input name="m_s_1" id="m_s_1" value={this.state.m_s_1} onChange={ e=>this.handleChange(e) }  type="time" className="form-control" onBlur={()=>isTimeValid('m_s_1','m_e_1')}/>
                                            </div>
                                            <span style={{color:"red"}} id="m_s_1_err">{this.state.m_s_1_err}</span>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label>Morning End</label>
                                                <input name="m_e_1" id="m_e_1"  value={this.state.m_e_1} onChange={ e=>this.handleChange(e) }  type="time" className="form-control" onBlur={()=>isTimeValid('m_s_1','m_e_1')}/>
                                            </div>
                                            <span style={{color:"red"}} id="m_e_1_err">{this.state.m_e_1_err}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                     <div className="row">
                                      <div className="col-md-10">
                                        <div className="form-group">
                                            <label>Maximum token</label>
                                            <input name="m_x_1" id="m_x_1" value={this.state.m_x_1} onChange={ e=>this.handleChange(e) } type="number" className="form-control" />
                                        </div>
                                        <span style={{color:"red"}} id="m_x_1_err">{this.state.m_x_1_err}</span>
                                      </div>
                                      <div className="col-md-2 justify-content-center d-flex flex-column"> 
                                            <a className="reset-style" onClick={()=>this.ResetFields('1','m')}>Reset</a>
                                      </div>
                                     </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-1 justify-content-center d-flex flex-column">
                                                <input type="checkbox"  className="check-class" id="a_c_1" name="a_c_1" value="1" checked={this.state.a_c_1} onChange={ e => this.handleChange(e) }/>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="form-group">
                                                    <label>Afternoon Start</label>
                                                    <input name="a_s_1" id="a_s_1"   placeholder="Date" type="time" className="form-control" onBlur={()=>isTimeValid('a_s_1','a_e_1')} value={this.state.a_s_1} onChange={ e=>this.handleChange(e) }/>
                                                </div>
                                                <span style={{color:"red"}} value="a_s_1_err">{this.state.a_s_1_err}</span>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="form-group">
                                                    <label>Afternoon End</label>
                                                    <input name="a_e_1" id="a_e_1"   type="time" className="form-control" onBlur={()=>isTimeValid('a_s_1','a_e_1')} value={this.state.a_e_1} onChange={ e=>this.handleChange(e) }/>
                                                </div>
                                                <span style={{color:"red"}} id="a_e_1_err">{this.state.a_e_1_err}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                     <div className="row">
                                      <div className="col-md-10">
                                        <div className="form-group">
                                            <label>Maximum token</label>
                                            <input name="a_x_1" id="a_x_1" type="number" className="form-control" value={this.state.a_x_1} onChange={ e=>this.handleChange(e) }/>
                                        </div>
                                        <span style={{color:"red"}} id="a_x_1_err"></span>
                                      </div>
                                      <div className="col-md-2 justify-content-center d-flex flex-column"> 
                                            <a className="reset-style" onClick={()=>this.ResetFields('1','a')}>Reset</a>
                                      </div>
                                     </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-1 justify-content-center d-flex flex-column">
                                                <input type="checkbox" className="check-class" id="e_c_1" name="e_c_1" value="1" checked={this.state.e_c_1} onChange={ e => this.handleChange(e) } />
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="form-group">
                                                    <label>Evening Start</label>
                                                    <input name="e_s_1" id="e_s_1"   placeholder="Date" type="time" className="form-control" onBlur={()=>isTimeValid('e_s_1','e_e_1')} value={this.state.e_s_1} onChange={ e=>this.handleChange(e) }/>
                                                </div>
                                                <span style={{color:"red"}} id="e_s_1_err">{this.state.e_s_1_err}</span>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="form-group">
                                                    <label>Evening End</label>
                                                    <input name="e_e_1" id="e_e_1"   type="time" className="form-control" onBlur={()=>isTimeValid('e_s_1','e_e_1')}  value={this.state.e_e_1} onChange={ e=>this.handleChange(e) }/>
                                                </div>
                                                <span style={{color:"red"}} id="e_e_1_err">{this.state.e_e_1_err}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                     <div className="row">
                                      <div className="col-md-10">
                                        <div className="form-group">
                                            <label>Maximum token</label>
                                            <input name="e_x_1" id="e_x_1" type="number" className="form-control"  value={this.state.e_x_1} onChange={ e=>this.handleChange(e) }/>
                                        </div>
                                        <span style={{color:"red"}} id="e_x_1_err"></span>
                                      </div>
                                      <div className="col-md-2 justify-content-center d-flex flex-column"> 
                                            <a className="reset-style" onClick={()=>this.ResetFields('1','e')}>Reset</a>
                                      </div>
                                     </div>
                                    </div>

                                </div>
                                {/* <a className="btn btn-main" onClick={this.DeleteAvailability} style={{color:"white",backgroundColor:"red",borderColor:"2px solid red",margin:"5px"}}><i style={{fontSize:"20px"}} className="fa fa-trash"></i></a> */}
                                <a className="btn btn-main btn-round-full delete-availability" onClick={this.DeleteAvailability}>Delete availability<i className="icofont-simple-right ml-2"></i></a>
                                <a className="btn btn-main btn-round-full save-availability" onClick={this.DailyAvailabilitySetting}>Save data<i className="icofont-simple-right ml-2"></i></a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </>
    );
  }
}
export default DailyAvailabilityComponent;