import moment from 'moment';

function ListBookingComponent(data) { 
const result = data.object;
let i = 0;
let borderclass ='';
if(result != 'undefined' && result.length > 0) { 
   return(
    <>
        {result.map(function(d, idx){
            if(i === 0) {
                borderclass = 'post-bx';
                i = 1;
            } else {
                borderclass = 'post-bx1';
                i = 0;
            }
            let the_date = moment(d.the_date).format('DD-MM-YYYY');
            let url = "/profile/" + d.slug + "/" + d.the_date;
            let map_url = "http://maps.google.com/?q=1200 " + d.company_location_slug;
            return(
                <li className="post-li-padding" key={idx}>
                        <div className={borderclass}>
                        <div className="">
                            <div className="job-post-company">
                                <div className="row justify-content-center">
                                    <div className="col-md-3">
                                     <div className="outer-doctor"><div className="inner-doctor inner-list-token"><b>Token No</b></div><div className="inner-inner-doctor"><span>{d.token_no}</span></div></div>
                                    </div>
                                    <div className="col-md-9">
                                      <span><h3>{the_date}</h3> <span className="list-para">{d.name} <b>|</b> {d.specialization} <b>|</b> {d.company_name}</span></span><a href={url}><button className="btn btn-danger btn-sm" style={{float:"right"}}>Details</button></a>
                                      <p><b>Total tokens:  {d.maxtoken} &nbsp;&nbsp;&nbsp; Live token:  {d.live_token} </b></p>
                                      <span className="slot-list">  <b>{d.slot_type}  : {d.time_period}</b></span>
                                      <a target="_blank" href={map_url} >
                                        <i className="fa fa-map-marker font-aw" aria-hidden="true">&nbsp;<u>{d.company_location}</u></i>
                                      </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </li>
            );
        })}
    </>
   );
 } else {
     return(
        <li className="post-li-padding">
            <div style={{margin:"100px"}}>
                <div className="job-post-company">
                    <div className="row justify-content-center">
                        <img src={process.env.REACT_APP_IMAGE_URL + 'no-bookings.png'} alt="No logo" className="img-fluid" />
                        <h5 className="mb-3 d-flex flex-column no-booking-quote">You currently doesn't have any bookings. Click the search button to book an appointment.</h5>
                    <button onClick={ () => window.location.href = '/search'} type="button" className="btn btn-main-2 btn-round-full btn-icon search-btn-doctor">Search</button>
                    </div>
                </div>
            </div>
        </li>
     );
 }
}
export default ListBookingComponent;